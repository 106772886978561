import React from 'react';

function NavTop() {
  return (
    <div className="nav-top pt-30 pb-30">
      <div className="container">
        <div className="row">
          <div className="col-md-4 valign">
            <a href="#0" className="logo icon-img-60">
              {/* <img src="/assets/imgs/logo-dark.png" alt="" /> */}
              <img src="/assets/imgs/personal-logo.png" alt="" />
            </a>
          </div>
          <div className="col-md-4 valign">
            <div className="social text-center full-width">
              {/* <a href="#0">
                <i className="fab fa-behance"></i>
              </a> */}
              <a href="https://github.com/Sage4tk" target='_blank'>
                <i className="fab fa-github"></i>
              </a>
              <a href="https://www.linkedin.com/in/timothy-zack-timbol-90b5271b6/" target='_blank'>
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a href="https://www.instagram.com/cactusz4ck/" target='_blank'>
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://www.youtube.com/channel/UCW2dpRlBaIujUvqiI6tbn_Q" target='_blank'>
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>
          <div className="col-md-4 valign">
            <div className="full-width info">
              <div className="d-flex justify-content-end">
                <a href="mailto:ttimbol08@gmail.com">
                  <span className="sub-title fz-12">ttimbol08@gmail.com</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="nav-butn">
          <span className="pe-7s-menu"></span>
        </div>
      </div>
    </div>
  );
}

export default NavTop;
