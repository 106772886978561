import React from 'react';
import { Link } from 'react-router-dom';

const Trabchat = ({

}) => {


  return (
    <section className="sec-box project section-padding radius-15">
      <div className="row justify-content-center">
        <div className="col-lg-11">
          <div className="img mb-80">
            <img
              src="/projects/trabchat/tc1.jpg"
              alt=""
              className="radius-5"
            />
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="cont md-mb50">
                <h3 className="mb-15 fw-500">TRABCHAT</h3>
                <p>
                Trabchat represents the inaugural project I undertook in my professional career, and it's an innovative app that combines elements of LinkedIn's professional networking with the visual and social engagement of Instagram. The genesis of this project was quite unique – it originated from a concept featured in a movie created by an Emirati filmmaker. Intrigued by this idea portrayed in the film, they approached our team to transform this cinematic concept into a tangible, functioning application. This opportunity not only marked the beginning of my professional journey but also challenged me to blend professional networking with social media elements in a cohesive and engaging platform.
                </p>
                <p className="mt-15">
                For Trabchat, my role was primarily focused on the backend development, where I utilized Node.js to build a robust and scalable server-side architecture. The application is hosted on AWS (Amazon Web Services), which provided a reliable and efficient cloud computing environment to support the app's functionality and user demands. This experience allowed me to deepen my expertise in backend technologies and cloud-based hosting solutions, playing a crucial role in bringing the Trabchat app to life.
                </p>
                <div className="mt-30">
                  {/* <h6 className="mb-15 line-height-28">
                    Revolutionizing Spending Insight
                  </h6>
                  <p>
                    Monee AE seemlessly integrates with your banking and provides powerful insights and features like setting a budget for a certain category of that and where your money typically goes in a time span.
                  </p> */}
                  <ul className="rest list-arrow mt-50">
                    <li>
                      <span className="icon">
                        <svg
                          width="100%"
                          height="100%"
                          viewBox="0 0 9 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clipRule="evenodd"
                            d="M7.71108 3.78684L8.22361 4.29813L7.71263 4.80992L4.64672 7.87832L4.13433 7.36688L6.87531 4.62335H1.11181H0.750039H0.388177L0.382812 0.718232H1.10645L1.11082 3.90005H6.80113L4.12591 1.22972L4.63689 0.718262L7.71108 3.78684Z"
                            fill="#fff"
                          ></path>
                        </svg>
                      </span>
                      <h6 className="inline fz-18">Express JS</h6>
                    </li>
                    <li className="mt-20">
                      <span className="icon">
                        <svg
                          width="100%"
                          height="100%"
                          viewBox="0 0 9 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clipRule="evenodd"
                            d="M7.71108 3.78684L8.22361 4.29813L7.71263 4.80992L4.64672 7.87832L4.13433 7.36688L6.87531 4.62335H1.11181H0.750039H0.388177L0.382812 0.718232H1.10645L1.11082 3.90005H6.80113L4.12591 1.22972L4.63689 0.718262L7.71108 3.78684Z"
                            fill="#fff"
                          ></path>
                        </svg>
                      </span>
                      <h6 className="inline fz-18">
                        Typescript
                      </h6>
                    </li>
                    <li className="mt-20">
                      <span className="icon">
                        <svg
                          width="100%"
                          height="100%"
                          viewBox="0 0 9 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clipRule="evenodd"
                            d="M7.71108 3.78684L8.22361 4.29813L7.71263 4.80992L4.64672 7.87832L4.13433 7.36688L6.87531 4.62335H1.11181H0.750039H0.388177L0.382812 0.718232H1.10645L1.11082 3.90005H6.80113L4.12591 1.22972L4.63689 0.718262L7.71108 3.78684Z"
                            fill="#fff"
                          ></path>
                        </svg>
                      </span>
                      <h6 className="inline fz-18">Firebase</h6>
                    </li>
                    <li className="mt-20">
                      <span className="icon">
                        <svg
                          width="100%"
                          height="100%"
                          viewBox="0 0 9 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clipRule="evenodd"
                            d="M7.71108 3.78684L8.22361 4.29813L7.71263 4.80992L4.64672 7.87832L4.13433 7.36688L6.87531 4.62335H1.11181H0.750039H0.388177L0.382812 0.718232H1.10645L1.11082 3.90005H6.80113L4.12591 1.22972L4.63689 0.718262L7.71108 3.78684Z"
                            fill="#fff"
                          ></path>
                        </svg>
                      </span>
                      <h6 className="inline fz-18">MySQL (AWS RDS)</h6>
                    </li>
                    <li className="mt-20">
                      <span className="icon">
                        <svg
                          width="100%"
                          height="100%"
                          viewBox="0 0 9 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clipRule="evenodd"
                            d="M7.71108 3.78684L8.22361 4.29813L7.71263 4.80992L4.64672 7.87832L4.13433 7.36688L6.87531 4.62335H1.11181H0.750039H0.388177L0.382812 0.718232H1.10645L1.11082 3.90005H6.80113L4.12591 1.22972L4.63689 0.718262L7.71108 3.78684Z"
                            fill="#fff"
                          ></path>
                        </svg>
                      </span>
                      <h6 className="inline fz-18">AWS EC2</h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="info sub-bg" id="sticky_item">
                <ul>
                  <li className="mb-30">
                    <span className="sub-title">
                      <i className="far fa-calendar-alt mr-10"></i> Date :
                    </span>
                    <p>Ocotober 2022</p>
                  </li>
                  <li className="mb-30">
                    <span className="sub-title">
                      <i className="fas fa-list-ul mr-10"></i> Categories :
                    </span>
                    <p>Backend Development</p>
                  </li>
                  <li className="mb-30">
                    <span className="sub-title">
                      <i className="far fa-user mr-10"></i> Client :
                    </span>
                    <p>Classified</p>
                  </li>
                  {/* <li>
                    <span className="sub-title">
                      <i className="fas fa-globe mr-10"></i> Website :
                    </span>
                    <p>https://monee.ae</p>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="next-prev">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="d-flex align-items-center mt-80 pt-80 bord-thin-top">
              <div className="prev">
                <h6 className="sub-title">
                  <Link to="/works/itematch">
                    <i className="fas fa-long-arrow-alt-left"></i> Prev Project
                  </Link>
                </h6>
              </div>
              <div className="next ml-auto">
                <h6 className="sub-title">
                  <Link to="/works/oppeinae">
                    next Project <i className="fas fa-long-arrow-alt-right"></i>
                  </Link>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Trabchat;
