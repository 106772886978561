import React from 'react';

function Profile() {
  return (
    <section className="intro-profile md-mb50">
      <div className="row rest">
        <div className="col-lg-4 box-img main-bg">
          <div className="cont valign">
            <div className="full-width">
              <div className="img">
                <img src="/light/assets/imgs/header/main-self.jpg" alt="" />
                <span className="icon">
                  <img src="/light/assets/imgs/header/icon1.png" alt="" />
                </span>
                <span className="icon">
                  <img src="/light/assets/imgs/header/icon2.png" alt="" />
                </span>
                <span className="icon">
                  <img src="/light/assets/imgs/header/icon4.png" alt="" />
                </span>
              </div>
              <div className="info text-center mt-30">
                <h5>Zack Timbol</h5>
                <p className="fz-13 text-u">Software Developer</p>
              </div>
              <div className="social text-center mt-20">
                {/* <a href="#0">
                  <i className="fab fa-behance"></i>
                </a> */}
                <a href="https://github.com/Sage4tk" target='_blank' rel="noreferrer">
                  <i className="fab fa-github"></i>
                </a>
                <a href="https://www.linkedin.com/in/timothy-zack-timbol-90b5271b6/" target='_blank' rel="noreferrer">
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a href="https://www.instagram.com/cactusz4ck/" target='_blank' rel="noreferrer">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="https://www.youtube.com/channel/UCW2dpRlBaIujUvqiI6tbn_Q" target='_blank' rel="noreferrer">
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8 content main-bg">
          <h1>
            Hello, I’m <span className="main-color">Zack Timbol</span>,{' '}
            <span className="bord">
              Full-Stack Developer <i></i>
            </span>{' '}
            Based in Dubai.
          </h1>
          <div className="stauts mt-80">
            <div className="d-flex align-items-center">
              <div className="mr-40">
                <div className="d-flex align-items-center">
                  <h2>2</h2>
                  <p>
                    Years <br /> of Experience
                  </p>
                </div>
              </div>
              <div className="mr-40">
                <div className="d-flex align-items-center">
                  <h2>30+</h2>
                  <p>
                    Clients <br /> Worldwide
                  </p>
                </div>
              </div>
              <div>
                <div className="butn-presv">
                  <a href="https://firebasestorage.googleapis.com/v0/b/myportoflio-1337.appspot.com/o/timothy-timbole-cv.docx?alt=media&token=d56a3dfc-3675-4144-ac46-89160442f1ae" className="butn butn-md butn-bord radius-5 skew">
                    <span>Download C.V</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Profile;
